import { createApp } from "vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Toaster from "@meforma/vue-toaster";

import router from "./router";

import { createStore } from "vuex";
import VueMobileDetection from "vue-mobile-detection";
import $axios from "./api";
import App from "./App.vue";

import Skeleton from "vue-loading-skeleton";
import "vue-loading-skeleton/dist/style.css";

const store = createStore({
  state() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      setting: JSON.parse(localStorage.getItem("setting")),
    };
  },
  mutations: {
    updateUser(state, newUser) {
      localStorage.setItem("user", JSON.stringify(newUser));
      state.user = newUser;
    },
    updateSetting(state, newSetting) {
      localStorage.setItem("setting", JSON.stringify(newSetting));
      state.setting = newSetting;
    },
  },
});

const app = createApp(App)
  .use(router)
  .use(VueSweetalert2)
  .use(store)
  .use(Toaster, {
    position: "top-right",
    dismissible: true,
    pauseOnHover: true,
  })
  .use(Skeleton)
  .use(VueMobileDetection);

app.config.globalProperties.$axios = { ...$axios };

app.use(store).mount("#app");
