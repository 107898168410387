<template>
  <nav
    class="pcoded-navbar menu-light brand-lightblue icon-colored menupos-static"
    :class="{
      'mob-open': sidebar,
    }"
  >
    <div class="navbar-wrapper">
      <div class="navbar-brand header-logo">
        <a href="#" class="b-brand">
          <div class="b-bg" v-if="this.$store.state.setting.app_logo == ''">
            <i class="feather home"></i>
          </div>
          <img
            v-else
            :src="base_url + this.$store.state.setting.app_logo"
            style="width: 30px"
          />
          <span class="b-title">{{ this.$store.state.setting.app_name }}</span>
        </a>
        <a class="mobile-menu" id="mobile-collapse" href="#!"><span></span></a>
      </div>
      <div class="navbar-content scroll-div">
        <ul class="nav pcoded-inner-navbar">
          <li class="nav-item pcoded-menu-caption">
            <label>Navigation</label>
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            :class="{
              active: $route.meta.menu == 'dashboard',
            }"
          >
            <router-link to="/dashboard" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-home"></i></span
              ><span class="pcoded-mtext">Dashboard</span></router-link
            >
          </li>
          <li
            class="nav-item pcoded-menu-caption"
            v-if="
              this.$store.state.user.role == 'admin' ||
              this.$store.state.user.role == 'hub'
            "
          >
            <label>Pengguna</label>
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            :class="{
              active: $route.meta.menu == 'group',
            }"
            v-if="this.$store.state.user.role == 'admin'"
          >
            <router-link to="/group" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-users"></i></span
              ><span class="pcoded-mtext">Kelompok</span></router-link
            >
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="
              this.$store.state.user.role == 'admin' ||
              this.$store.state.user.role == 'group' ||
              this.$store.state.user.role == 'hub'
            "
            :class="{
              active: $route.meta.menu == 'user',
            }"
          >
            <router-link to="/user" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-user"></i></span
              ><span class="pcoded-mtext">Peternak</span></router-link
            >
          </li>

          <li
            class="nav-item pcoded-menu-caption"
            v-if="
              this.$store.state.user.role == 'admin' ||
              this.$store.state.user.role == 'group' ||
              this.$store.state.user.role == 'hub'
            "
          >
            <label>Tindak Lanjut</label>
          </li>

          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="
              this.$store.state.user.role == 'group' ||
              this.$store.state.user.role == 'hub'
            "
            :class="{
              active: $route.meta.menu == 'seal',
            }"
          >
            <router-link to="/seal" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-lock"></i></span
              ><span class="pcoded-mtext">Pengelolaan Segel</span></router-link
            >
          </li>

          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="
              this.$store.state.user.role == 'admin' ||
              this.$store.state.user.role == 'group'
            "
            :class="{
              active: $route.meta.menu == 'pending_harvest',
            }"
          >
            <router-link to="/pending_harvest" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-airplay"></i></span
              ><span class="pcoded-mtext"
                >Pengelolaan Harvest</span
              ></router-link
            >
          </li>

          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="
              this.$store.state.user.role == 'admin' ||
              this.$store.state.user.role == 'group' ||
              this.$store.state.user.role == 'hub'
            "
            :class="{
              active: $route.meta.menu == 'shipping',
            }"
          >
            <router-link to="/shipping" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-navigation"></i></span
              ><span class="pcoded-mtext">Pengiriman</span></router-link
            >
          </li>

          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="
              this.$store.state.user.role == 'admin' ||
              this.$store.state.user.role == 'hub'
            "
            :class="{
              active: $route.meta.menu == 'production',
            }"
          >
            <router-link to="/production" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-package"></i></span
              ><span class="pcoded-mtext">Produksi</span></router-link
            >
          </li>

          <li
            class="nav-item pcoded-menu-caption"
            v-if="this.$store.state.user.role == 'admin'"
          >
            <label>Master Data</label>
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'queen',
            }"
          >
            <router-link to="/queen" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="fas fa-chess-queen"></i></span
              ><span class="pcoded-mtext">Ratu Lebah</span></router-link
            >
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'seed',
            }"
          >
            <router-link to="/seed" class="nav-link"
              ><span class="pcoded-micon"><i class="fab fa-forumbee"></i></span
              ><span class="pcoded-mtext">Bibit</span></router-link
            >
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'treatment',
            }"
          >
            <router-link to="/treatment" class="nav-link"
              ><span class="pcoded-micon"><i class="fas fa-heart"></i></span
              ><span class="pcoded-mtext">Treatment</span></router-link
            >
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'food',
            }"
          >
            <router-link to="/food" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="fas fa-prescription-bottle"></i></span
              ><span class="pcoded-mtext">Makanan</span></router-link
            >
          </li>

          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'box',
            }"
          >
            <router-link to="/box" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-inbox"></i></span
              ><span class="pcoded-mtext">Box</span></router-link
            >
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'frame',
            }"
          >
            <router-link to="/frame" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather feather icon-minus-square"></i></span
              ><span class="pcoded-mtext">Frame</span></router-link
            >
          </li>

          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'product',
            }"
          >
            <router-link to="/product" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-layers"></i></span
              ><span class="pcoded-mtext">Produk Madu</span></router-link
            >
          </li>

          <li
            class="nav-item pcoded-menu-caption"
            v-if="this.$store.state.user.role == 'admin'"
          >
            <label>Pengaturan</label>
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'setting',
            }"
          >
            <router-link to="/setting" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-settings"></i></span
              ><span class="pcoded-mtext"
                >Pengaturan Aplikasi</span
              ></router-link
            >
          </li>

          <li
            class="nav-item pcoded-menu-caption"
            v-if="this.$store.state.user.role == 'admin'"
          >
            <label>Log</label>
          </li>
          <li
            data-username="Sample Page"
            class="nav-item"
            v-if="this.$store.state.user.role == 'admin'"
            :class="{
              active: $route.meta.menu == 'log',
            }"
          >
            <router-link to="/log" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather icon-list"></i></span
              ><span class="pcoded-mtext">Aktivitas Pengguna</span></router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <header class="navbar pcoded-header navbar-expand-lg navbar-light">
    <div class="m-header">
      <span class="mobile-menu" id="mobile-collapse1" @click="toggleSidebar()"
        ><span></span
      ></span>
      <a href="#" class="b-brand">
        <div class="b-bg" v-if="this.$store.state.setting.app_logo == ''">
          <i class="feather home"></i>
        </div>
        <img
          v-else
          :src="base_url + this.$store.state.setting.app_logo"
          style="width: 30px"
        />
        <span class="b-title">{{ $store.state.setting.app_name }}</span>
      </a>
    </div>
    <a class="mobile-menu" id="mobile-header" href="#!">
      <i class="feather icon-more-horizontal"></i>
    </a>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <li>
          <a
            href="#!"
            class="full-screen"
            onclick="javascript:toggleFullScreen()"
            ><i class="feather icon-maximize"></i
          ></a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li>
          <div class="dropdown drp-user">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <i class="icon feather icon-settings"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-notification">
              <div class="pro-head">
                <img
                  :src="
                    userState != null
                      ? '/assets/images/user/avatar-2.jpg'
                      : base_url + userState.profile_picture
                  "
                  class="img-radius"
                />
                <span>{{ userState.name }}</span>
                <a
                  href="javascript:void(0)"
                  @click="logout()"
                  class="dud-logout"
                  title="Logout"
                >
                  <i class="feather icon-log-out"></i>
                </a>
              </div>
              <ul class="pro-body">
                <li>
                  <router-link to="/profile" class="dropdown-item"
                    ><i class="feather icon-user"></i> Profile</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </header>

  <div class="pcoded-main-container" @click="sidebar = false">
    <div class="pcoded-wrapper">
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="page-header">
            <div class="page-block">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="page-header-title">
                    <h5 class="m-b-10">
                      {{ this.$route.name }}
                    </h5>
                  </div>
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#"><i class="feather icon-home"></i></a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="#!">
                        {{ this.$route.path.substring(1) }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="main-body">
            <div class="page-wrapper">
              <!-- main content -->
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainLayout",
  data: () => ({
    base_url: process.env.VUE_APP_BASE_URL,
    sidebar: false,
  }),
  computed: {
    userState: function () {
      return this.$store.state.user;
    },
  },
  watch: {
    $isMobile() {
      if (!this.$isMobile()) {
        this.sidebar = false;
      }
    },
  },
  mounted() {
    document.getElementById("mainBody").style.background = "#fbc02d";

    if (!this.$isMobile()) {
      this.sidebar = false;
    }
  },
  created() {
    this.$axios.get("/setting").then((res) => {
      this.$store.commit("updateSetting", res.data.setting);
      document.title = res.data.setting.app_name;
    });
  },
  methods: {
    logout() {
      this.$swal({
        title: "Anda Akan Keluar Dari Aplikasi",
        text: "Lanjut?",
        icon: "warning",
        showCancelButton: true,
        buttons: true,
        dangerMode: true,
      }).then((value) => {
        if (value.isConfirmed) {
          this.$axios.post("/logout");
          this.$store.commit("updateUser", null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          delete this.$axios.defaults.headers.Authorization;
          this.$router.push("/");
        }
        return;
      });
    },
    toggleSidebar() {
      if (this.$isMobile()) {
        this.sidebar = !this.sidebar;
      }
    },
  },
};
</script>
<style>
#mainBody {
  background: #fbc02d;
}
</style>
