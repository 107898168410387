import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Login Page",
    component: () => import("@/components/LoginPage"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard Page",
    component: () => import("@/components/DashboardPage"),
    meta: {
      layout: "main",
      menu: "dashboard",
    },
  },
  {
    path: "/user",
    name: "Petani",
    component: () => import("@/components/User/User"),
    children: [
      {
        path: "",
        name: "Daftar Petani",
        component: () => import("@/components/User/Index"),
      },
      {
        path: "create",
        name: "Tambah Petani",
        component: () => import("@/components/User/Create"),
      },
      {
        path: ":user_id",
        name: "Edit Petani",
        component: () => import("@/components/User/Edit"),
      },
      {
        path: "detail/:user_id",
        name: "Detail Petani",
        component: () => import("@/components/User/Detail"),
      },
    ],
    meta: {
      layout: "main",
      menu: "user",
    },
  },
  {
    path: "/group",
    name: "Kelompok",
    component: () => import("@/components/Group/Group"),
    children: [
      {
        path: "",
        name: "Daftar Kelompok",
        component: () => import("@/components/Group/Index"),
      },
      {
        path: "create",
        name: "Tambah Kelompok",
        component: () => import("@/components/Group/Create"),
      },
      {
        path: ":group_id",
        name: "Edit Kelompok",
        component: () => import("@/components/Group/Edit"),
      },
      {
        path: ":group_id/detail",
        name: "Detail Kelompok",
        component: () => import("@/components/Group/Detail"),
      },
    ],
    meta: {
      layout: "main",
      menu: "group",
    },
  },
  {
    path: "/box",
    name: "Management Box",
    component: () => import("@/components/Box/Box"),
    children: [
      {
        path: "",
        name: "Daftar Box",
        component: () => import("@/components/Box/Index"),
      },
      {
        path: "maintenance/:box_id",
        name: "Box Maintenance",
        component: () => import("@/components/Box/Maintenance"),
      },
    ],
    meta: {
      layout: "main",
      menu: "box",
    },
  },
  {
    path: "/frame",
    name: "Management Frame",
    component: () => import("@/components/Frame/Frame"),
    children: [
      {
        path: "",
        name: "Daftar Frame",
        component: () => import("@/components/Frame/Index"),
      },
      {
        path: "box/:box_id",
        name: "Daftar Frame Berdasarkan Box",
        component: () => import("@/components/Frame/Box"),
      },
      {
        path: "maintenance/:frame_id",
        name: "Frame Maintenance",
        component: () => import("@/components/Frame/Maintenance"),
      },
      {
        path: "transfer/:frame_id",
        name: "Frame Transfer",
        component: () => import("@/components/Frame/Transfer"),
      },
    ],
    meta: {
      layout: "main",
      menu: "frame",
    },
  },
  {
    path: "/queen",
    name: "Management Ratu Lebah",
    component: () => import("@/components/Queen/Queen"),
    children: [
      {
        path: "",
        name: "Daftar Ratu Lebah",
        component: () => import("@/components/Queen/Index"),
      },
    ],
    meta: {
      layout: "main",
      menu: "queen",
    },
  },
  {
    path: "/seed",
    name: "Management Bibit Lebah",
    component: () => import("@/components/Seed/Seed"),
    children: [
      {
        path: "",
        name: "Daftar Bibit Lebah",
        component: () => import("@/components/Seed/Index"),
      },
    ],
    meta: {
      layout: "main",
      menu: "seed",
    },
  },
  {
    path: "/treatment",
    name: "Management Perawatan",
    component: () => import("@/components/Treatment/Treatment"),
    children: [
      {
        path: "",
        name: "Daftar Perawatan",
        component: () => import("@/components/Treatment/Index"),
      },
    ],
    meta: {
      layout: "main",
      menu: "treatment",
    },
  },
  {
    path: "/food",
    name: "Management Makanan Lebah",
    component: () => import("@/components/Food/Food"),
    children: [
      {
        path: "",
        name: "Daftar Makanan",
        component: () => import("@/components/Food/Index"),
      },
    ],
    meta: {
      layout: "main",
      menu: "food",
    },
  },
  {
    path: "/product",
    name: "Management Produk Madu",
    component: () => import("@/components/Product/Product"),
    children: [
      {
        path: "",
        name: "Daftar Produk Madu",
        component: () => import("@/components/Product/Index"),
      },
    ],
    meta: {
      layout: "main",
      menu: "product",
    },
  },
  {
    path: "/seal/print",
    name: "Print Segel",
    component: () => import("@/components/QRSeal"),
    meta: {
      layout: "qr",
      menu: "seal",
    },
  },
  {
    path: "/seal",
    name: "Pengelolaan Segel",
    component: () => import("@/components/Seal/Seal"),
    children: [
      {
        path: "",
        name: "Pembuatan Segel",
        component: () => import("@/components/Seal/Index"),
      },
    ],
    meta: {
      layout: "main",
      menu: "seal",
    },
  },

  {
    path: "/profile",
    name: "Pengaturan Akun",
    component: () => import("@/components/Profile/Index"),
    meta: {
      layout: "main",
      menu: "profile",
    },
  },
  {
    path: "/setting",
    name: "Pengaturan Aplikasi",
    component: () => import("@/components/Setting/Index"),
    meta: {
      layout: "main",
      menu: "setting",
    },
  },
  {
    path: "/log",
    name: "Lok Aktivitas",
    component: () => import("@/components/Log/Index"),
    meta: {
      layout: "main",
      menu: "log",
    },
  },
  {
    path: "/qr",
    name: "QR Code",
    component: () => import("@/components/QR"),
    meta: {
      layout: "qr",
    },
  },
  {
    path: "/qr/box/:detail_user_id",
    name: "QR Code Boxes",
    component: () => import("@/components/QRBox"),
    meta: {
      layout: "qr",
    },
  },
  {
    path: "/qr/frame/:detail_user_id",
    name: "QR Code Frame",
    component: () => import("@/components/QRFrame"),
    meta: {
      layout: "qr",
    },
  },
  {
    path: "/qr/production/:code",
    name: "QR Production",
    component: () => import("@/components/QRProduction"),
    meta: {
      layout: "qr",
    },
  },
  {
    path: "/pending_harvest",
    name: "Pengelolaan Pending Harvest",
    component: () => import("@/components/Harvest/Harvest"),
    children: [
      {
        path: "",
        name: "Daftar Pending Harvest",
        component: () => import("@/components/Harvest/Index"),
      },
      {
        path: ":box_harvest_id",
        name: "Detail Pending Harvest",
        component: () => import("@/components/Harvest/Detail"),
      },
    ],
    meta: {
      layout: "main",
      menu: "pending_harvest",
    },
  },
  {
    path: "/shipping",
    name: "Pengiriman Hasil Harvest",
    component: () => import("@/components/Shipping/Shipping"),
    children: [
      {
        path: "",
        name: "Daftar Pengiriman Hasil Harvest",
        component: () => import("@/components/Shipping/Index"),
      },
    ],
    meta: {
      layout: "main",
      menu: "shipping",
    },
  },
  {
    path: "/production",
    name: "Produksi",
    component: () => import("@/components/Production/Production"),
    children: [
      {
        path: "",
        name: "Daftar Proses Produksi",
        component: () => import("@/components/Production/Index"),
      },
      {
        path: "create",
        name: "Tambah Proses Produksi",
        component: () => import("@/components/Production/Create"),
      },
      {
        path: ":id",
        name: "Detail Proses Produksi",
        component: () => import("@/components/Production/Show"),
      },
      {
        path: ":id/frame",
        name: "Detail Proses Foto Produksi ",
        component: () => import("@/components/Production/Foto"),
      },
    ],
    meta: {
      layout: "main",
      menu: "production",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
