<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "QRCode",
  created() {
    this.$axios.get("/setting").then((res) => {
      this.$store.commit("updateSetting", res.data.setting);
      document.title = res.data.setting.app_name;
    });
  },
};
</script>
<style>
#mainBody {
  background: #ffffff;
}
</style>
